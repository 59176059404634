@import "./variables";
@import "./theme";

.navbar {
  position: sticky;
  top: 0;
  transition: background-color 0.3s, color 0.3s;
  opacity: 1;
  width: 100%;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  box-sizing: border-box;
  font-family: $avantt-regular;
  font-size: 1.25rem;
  padding: 21px 33px;

  &.homepage {
    background-color: white;
    border-bottom: 3px solid #ea5f26;
  }

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.8);
    color: black !important;
  }

  &.wellness {
    background-color: #f2ece9 !important;
    color: black !important;
    border-radius: 0;
    font-size: 1.25rem;
  }

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    color: black !important;

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .blueberry-logo {
      display: flex;
      height: 4rem;
      width: 4rem;
    }
    .navi-logo {
      display: flex;
      height: 7rem;
      width: 7rem;
    }
    .logo-text {
      font-size: 1rem;
    }
    .pages-links,
    .auth-links {
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        color: $black;
        font-family: $avantt-regular;
        font-size: $text_md;
        font-weight: bold;

        label {
          align-items: center;

          select {
            margin-left: 10px;
            background-color: #1b5bbc;
            // color: white;
            border-radius: 100px;
            outline: none;
            padding: 4px;
          }
        }

        a {
          // color: var(--light);
          font-family: $avantt-regular;
          font-size: $text_md;
          font-weight: bold;
        }

        .signUp {
          padding: 13px 34px;
          background-color: var(--primary);
          color: var(--primary);
          font-family: $avantt-regular !important;
          font-size: $text_md;
          font-weight: bold;
          border-radius: 10px;
        }

        .login {
          font-family: $avantt-regular !important;
          font-size: $text_md;
          font-weight: bold;
        }

        .logout {
          background-color: transparent;
          border: none;
          font-family: $avantt-regular;
          font-size: $text_md;
          font-weight: bold;
          color: var(--light);
        }

        .yourHome {
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 600px) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    .pages-links {
      max-width: 600px;
      width: 100%;
    }

    // .wellness-auth-links {
    //   max-width: 200px;
    // }

    @media screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 920px) {
    display: none;
  }
}

.wellness {
  // background-color: $blue !important;

  a,
  button {
    // color: $white !important;
    display: flex;
    align-items: center;
  }
}

.wellness_button {
  background-color: transparent !important;
  font-family: $avantt-bold !important;
  padding: 0 !important;
}

#google_translate_element {
  .skiptranslate.goog-te-gadget {
    color: rgba(255, 255, 255, 0);
    font: 0px;
    line-height: 0px;
  }

  span {
    display: none;
  }

  select {
    outline: none;
    padding: 5px;
    border-radius: 100px;
    width: fit-content;
    // background-color: #1b5bbc;
  }
}

iframe.skiptranslate {
  display: none;
}

body {
  top: 0px !important;
}
